<template>
  <div>
    <b-table
      hover
      responsive
      per-page="100"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      sticky-header="700px"
      show-empty
    >
      <!-- show empty data -->
      <template #empty>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- show empty filtered -->
      <template #emptyfiltered>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- custom cell scraper name -->
      <template #cell(scraper_name)="data">
        <div class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell scraper name -->
      <template #cell(_id)="data">
        <div class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell no -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- custom cell start -->
      <template #cell(date)="data">
        <b-badge variant="light-success">{{
          data.value ? parseDate(data.value) : "-"
        }}</b-badge>
      </template>
      <!-- custom cell start -->
      <template #cell(timestamp)="data">
        <b-badge variant="light-success">{{
          data.value ? parseDate(data.value) : "-"
        }}</b-badge>
      </template>
      <!-- custom cell start -->
      <template #cell(url)="data">
        <a :href="data.value" target="_blank">
          {{ data.value }}
        </a>
      </template>

      <!-- custom cell start -->
      <template #cell(start)="data">
        <b-badge variant="light-success">{{
          data.value ? parseDate(data.value) : "-"
        }}</b-badge>
      </template>

      <!-- custom cell end -->
      <template #cell(end)="data">
        <b-badge variant="light-success">{{
          data.value ? parseDate(data.value) : "-"
        }}</b-badge>
      </template>

      <!-- custom cell duration -->
      <template #cell(duration)="data">
        {{ data.value ? data.value : 0 }}
      </template>

      <!-- custom cell count -->
      <template #cell(count)="data">
        {{ data.value ? data.value : 0 }}
      </template>
      <!-- custom cell username -->
      <template #cell(username)="data">
        <div :class="data.value ? '' : 'text-center'">
          {{ data.value ? data.value : "-" }}
        </div>
      </template>

      <!-- custom cell keterangan -->
      <template #cell(keterangan)="data">
        <div :class="data.value ? '' : 'text-justify'">
          {{ data.value ? data.value : "-" }}
        </div>
      </template>

      <!-- custom cell solution -->
      <template #cell(solution)="data">
        <div :class="data.value ? '' : 'text-center'">
          {{ data.value ? data.value : "-" }}
        </div>
      </template>

      <!-- custom cell status -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.value == 'Error'"
          pill
          variant="danger"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Completed'"
          pill
          variant="success"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Fixed'"
          pill
          variant="success"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Redirected'"
          pill
          variant="info"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Unavailable'"
          pill
          variant="secondary"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'OnProgress'"
          pill
          variant="secondary"
          style="width: 7rem !important"
        >
          On Progress
        </b-badge>
        <b-badge
          v-else-if="data.value == 'MissingClass'"
          pill
          variant="warning"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
        <b-badge
          v-else-if="data.value == 'Running'"
          pill
          variant="primary"
          style="width: 7rem !important"
        >
          {{ data.value }}
        </b-badge>
      </template>

      <!-- custom cell error_message -->
      <template #cell(error_message)="data">
        <div
          v-if="Array.isArray(data.value)"
          class="scroller"
          style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
        >
          <div
            v-for="(item, index) in data.value"
            :key="index"
            class="border rounded mb-1 p-1"
          >
            <div class="row">
              <div class="col-md-2 d-flex pr-0">
                URL <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.url }}</div>
              <div class="col-md-2 d-flex pr-0">
                Error <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.error }}</div>
              <div class="col-md-2 d-flex pr-0">
                Type <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.type }}</div>
              <div class="col-md-2 d-flex pr-0">
                Line <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.line }}</div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="scroller"
          style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
        >
          {{
            data.value
              ? data.value.length > 200
                ? data.value.substring(0, 200) + "..."
                : data.value
              : "-"
          }}
        </div>
      </template>
    </b-table>
    <pagination-component :data="data.page_info" />
  </div>
</template>

<script>
import helper from "@/helper/helper.js";
import pagination from "./pagination.vue";
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";

export default {
  name: "dataTable",
  components: {
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    VBTooltip,
    BBadge,
    "pagination-component": pagination,
  },

  props: {
    data: Object,
  },
  data() {
    return {
      is_copied: false,
      text_copied: null,
    };
  },
  methods: {
    copyToClipboard(text) {
      this.text_copied = text;
      navigator.clipboard.writeText(text).then(() => {
        this.is_copied = true;
        setTimeout(() => {
          this.is_copied = false;
          this.text_copied = null;
        }, 1000);
      });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
